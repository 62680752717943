.faq-button, .opp-search-button, .applicant-report-button {
  padding: 8px 16px;
  white-space: nowrap;
  display: inline-block;

  a {
    text-decoration: none;
    color: $white;

    @include hover-focus-active {
      color: $white;
    }
  }
}


.header-button,
.header-button.btn {
  text-decoration: none;
  color: $white;

  @include hover-focus-active {
    color: $white  !important;
  }

  a {
    @include hover-focus-active {
      color: $white;
    }
  }
}

.login-button {
  padding: 8px 16px;
  white-space: nowrap;
  display: inline-block;

  .login-icon {
    margin-top: -3px;
  }

  a {
    text-decoration: none;
    color: $white;

    @include hover-focus-active {
      color: $white;
    }
  }

  span {
    padding-left: 6px;
  }
}

.login-submitbutton {
  min-width: 100px;
  max-width: 300px;
  border: 2px solid white;

  @include hover-focus-active {
    border: 2px solid white;
  }
}

.login-form, .login-opp-search {
  .form-label {
    color: $white;
  }
}

.forgot-password-link, .old-clc-link {
  text-decoration: none;
  color: $white  !important;

  @include hover-focus-active {
    color: $secondary;
  }
}

.login-links {
  color: $white;
}

.search-btn {
  align-self: end;

  button {
    border: 2px solid white;
    @include hover-focus-active {
      border: 2px solid white;
    }
  }

}

.login-search-text {
  color: $white;
}

.old-clc-link {
  background: padding-box;
  border: none;
}

/* Default order for larger screens */
.login-panels {
  display: flex;
  flex-direction: row;
}

@media (max-width:768px) {
  .login-panels {
    display: flex;
    flex-direction: column-reverse;
  }

  .login-panel {
    background: rgba(26, 67, 138, 0.9);
  }

  .login-form {
    margin-top: 15px;
  }
}
