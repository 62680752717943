/*
  Settings – used with preprocessors and contain font, colors definitions, etc.
  Tools – globally used mixins and functions
  Elements – styling for bare HTML elements (like H1, A, etc.)
  Layouts – styling for global layout elements
  Objects – are the smallest building blocks of a website
  Components – specific UI components.
  Utilities – utilities and helper classes with ability to override anything
 */

// Settings
@import '1_settings/index';

// Tools
@import '2_tools/index';

// Elements
@import '3_elements/index';

// Layouts
@import '4_layouts/index';

// Objects
@import '5_objects/index';

// Components
@import '6_components/index';

// Utilities
@import '7_utilities/index';

// Extras
@import '8_extras/index';

.login-background {
  background-image: url('../assets/images/CLC-Background.jpg');
  width: 100vw;
  height: calc(91vh - 70px);
  padding-top: 16px;
  margin: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width:768px) {
  .login-background {
    height: calc(100vh - 70px);
  }

  .state-checkbox-scroll {
    margin-top: 20px; /* Adjust this value as needed */
  }

  .presbytery-permissions {
    padding: 10px 12px 0px 12px;
  }
}

@media (min-width:768px) {
  .mdp-results-left-panel {
    padding-right: 0;
  }

  .mdp-results-right-panel {
    padding-left: 0;
  }

  .active-card {
    border-right: 0 !important;
  }

  .mdp-details {
    border-left: 0 !important;
  }
}

.login-header {
  text-align: center;
  color: white;
}

#basic-nav-dropdown,
#user-nav-dropdown {
  color: white !important;
  display: inline-block;
}

.clc-nav {
  background-color: $primary;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
  color: $white;
  z-index: 1036;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.function-column {
  width: 30%;
}

.allowed-roles-column {
  width: 70%;
}

.clc-text-1,
.clc-text-2 {
  text-align: left;
  color: $white;
}

.clc-text-2 {
  font-size: smaller;
  display: block;
  color: $secondary;
}

.questionstatement {
  font-family: $base-font;
  font-weight: $fw-bold;
  font-size: 125%;
}

.questiontags {
  font-family: $base-font;
  font-weight: $fw-thin;
}

.activated {
  color: $black;
}

.deactivated {
  color: $gray-100;
}

.headerdata {
  font-size: 125%;
  font-family: $base-font;
  font-weight: $fw-bold;
  padding-top: 1%;
  margin-right: 5%;
  margin-left: 5%;
}

.itemstatement {
  font-size: 125%;
  font-family: $base-font;
  font-weight: $fw-bold;
  padding-bottom: 1%;
  margin-right: 5%;
  margin-left: 5%;
}

.itemdescription {
  font-family: $base-font;
  font-weight: $fw-thin;
  padding-bottom: 1%;
  margin-right: 5%;
  margin-left: 5%;
}

.sectionheader {
  font-size: 150%;
  font-family: $base-font;
  font-weight: $fw-bold;
  padding-top: 1%;
  max-width: 85%;
  margin-right: 5%;
  margin-left: 5%;
}

.progresslabel {
  background-color: $white;
  color: $gray-500;
  font-weight: $fw-thin;
  text-align: left;
  font-size: 80%;
  font-family: $base-font;
  padding-bottom: 1%;
  margin-right: auto;
  margin-left: auto;
  max-width: 90%;
  white-space: nowrap;
}

.wholeprogress {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  padding-top: 1%;
  background-color: $white;
  z-index: 999;
}

.progressbar {
  border-radius: 30px;
}

.progress-bar {
  background-color: $secondary;
  border-radius: 30px;
}

.separator {
  margin-top: 5%;
}

.saveexit {
  color: $secondary;
  font-weight: $fw-thin;
  font-size: 80%;
  padding-left: 70%;
  white-space: nowrap;
}

.user-name {
  background: #092a46;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px $black;
}

.title {
  font-size: 1.5em;
  font-weight: $fw-bold;
  margin-bottom: 10px;
}

.bodyheader {
  font-weight: $fw-bold;
}

.misconducttitle {
  font-weight: 800;
  font-size: 20px;
}

.misconductparagraph {
  white-space: pre-wrap;
}

.misconductalignradio {
  text-align: left;
  margin-top: -15px;
  width: 98%;

  input[name="MisconductRadio"] {
    border: 2px solid $info;
    width: 22px;
    height: 22px;
  }

  .form-check-label {
    position: relative;
    left: 5px;
    top: -25px;
  }
}

.error-text {
  color:#dc3545;
}

@include media-breakpoint-up(lg) {
  .salary {
    width: 30%;
  }
}

.asteriskparagraph {
  white-space: pre-wrap;
}

.pdpsurveyheader {
  text-align: start;
  background-color: $info;
  color: $white;
  margin-bottom: 30px;
  padding-inline-start: 10px;
}

.complaintbox {
  height: 100px;
  width: 96%;
}

@include media-breakpoint-up(xxl) {
  .misconductalignradio {
    input[name="MisconductRadio"] {
      margin-top: -25px;
    }
  }
}

.tableheader {
  background-color: $info;
  color: $white;

  :hover {
    background-color: $info;
    color: $white;
  }
}

.ordainedtitle {
  font-size: 12px;
}

.tablescroll {
  height: 300px;
  overflow-y: scroll;
}

.linkdesc {
  input[name="linkdescinput"] {
    height: 100px;
  }
}

.linktableheader {
  background: $info;
  color: $white;
  font-size: 14px;
}

.referraltable {
  margin: 0 auto;
  width:  90%;
}

.referraltableheader {
  background: lightskyblue;
  color: black;
  font-size: 14px;
  width: 500px;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}

.narrative-question {
  background: $info;
  color: $white;
  font-size: 18px;
}

.linkactions {
  cursor: pointer;
  color: $info;
  text-decoration: none;
  padding: 0px;

  svg {
    margin-top: -2px;
    margin-right: 5px;
  }

  :hover {
    opacity: 0.7;
  }
}

.actionicons {
  display: inline-flex;
  width: max-content;
}

.sortbutton {
  margin-top: 37px;
}

.createbutton, .printbutton {
  align-self: flex-end;
}

.reftable {
  background: $white;
  text-align: left;
  padding-top: 25px;
}

.reftablecols {
  text-align: left;
}

.profile-panel {
  margin: 15px 0px;
}

.accordion-header-label {
  margin-left: 5px;
  margin-right: 5px;
}

.user-profile-picture {
  color: $gray-500;
}

.reviewscroll {
  height: 200px;
  overflow: scroll;
  width: 100%;
}

.reviewtitle {
  font-weight: $fw-bold;
}

.callNotificationTable {
  td {
    border-bottom-width: 0;
    padding: 2px;
    margin-left: 10px;
  }
}

.callNotificationLabels {
  font-weight: bolder;
  width:50%;
  margin-right: 10px;
}

.callNotificationTitle {
  font-size: larger;
  font-weight: bolder;
  margin-top: 10px;
}

.callNotificationSummary {
  display: flex;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: normal;
  flex-direction: column;
  min-height: 100vh; /* Ensure it takes the full height of the viewport */
}

.text-wrap {
  flex-basis: fit-content;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.mdpdivider {
  width: 98%;
  margin: auto;
  height: 2px;
}

.strongdivider {
  border-width: medium;
  margin: 0 auto;
  width:  90%;
}

.match-criteria-divider {
  border-width: medium;
  margin: 0 auto;
  width:  100%;
}

.pdp-narrative-panel {
  text-align: left;
  margin-bottom: 0px;
  border: hidden;

  td {
    border-bottom-width: 0;
  }
}

.narrative-text-edit {
  width: 100%;
  border: 1px solid lightgray;
}

.non-field-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.matching-block {
  background-color: #F1F3F3;
  padding: 10px;
  margin: auto;
  width: fit-content;
}

.matching-criteria-block {
  background-color: #F1F3F3;
  padding: 10px;
  margin: auto;
}

.matching-criteria-block-header {
  width: 100%;
}

.arrow-icon {
  display: inline-block;
  transition: transform 0.3s ease;
}

.rotate-up {
  transform: rotate(180deg);
}

.rotate-down {
  transform: rotate(0deg);
}

// add border all around except left border
.mdp-details {
  background-color: #f2f2f2;
  border: 1px solid;
  border-color: rgba(0, 0, 0, .175);
}

.mdp-details-block {
  background-color: #fafafa;
  padding: 10px;
  margin: auto;
}

.mdp-details-secondary-text {
  color: #757575;
  opacity: 65%;
  font-weight: bold;
}

.caret {
  display:inline-block;
  opacity: 40%;
  width:0;
  height:0;
  margin-left:2px;
  vertical-align:middle;
  border-top:4px dashed;
  border-top:4px solid\9;
  border-right:4px solid transparent;
  border-left:4px solid transparent
 }
 .dropdown,
 .dropup {
  position:relative
 }
 .dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
 content:"";
 border-top:0;
 border-bottom:4px dashed;
 border-bottom:4px solid\9
}

.no-radius {
  border-radius: 0px;
}

ul.no-bullets {
  list-style-type: none;
}

.inline-list li {
  display: inline;
}

.active-card {
  background-color: #f2f2f2;
  border-left-width: thick;
  border-left-color: darkblue;
}

.action-button {
  min-width: 100px;
}

.state-checkbox-scroll {
  overflow-y: scroll;
  height: 156px;
}

.accordion-button {
  background-color: revert;
}

.table-action-display {
  display: flex;
}

.cancel-call-label {
  text-align: center;
  font-size: large;
}

.save-button-display {
  cursor: pointer;
  color: goldenrod;;
  text-decoration: none;
  padding: 0px;

  svg {
    margin-top: -2px;
    margin-right: 5px;
  }

  :hover {
    opacity: 0.7;
  }
}

.pagination button {
  margin-right: 1px;
}

.pagination-wrap {
  flex-flow: wrap;
}

.mdp-ref-email {
  overflow-wrap: anywhere;
}

.tags-readonly {
  input {
    display: none;
  }
}

/* custom-switch.css */
.custom-switch .form-check-input {
  background-image: url('../assets/images/icons8-circle-96.png');
}

.presbytery-permissions {
  padding: 10px 100px 0px 100px;
}

.mdp-contact-us {
  display: flex;
}

.shrink-font {
  width: 98%;
  font-size: 8pt;
}

.page-break-before {
  page-break-before: always;
}

.print-width {
  min-width: 8in;
}

.pdp-references-print {
  width: 33%;
}

.full-width-print {
  width: 100%;
}

@media print {
  html {
    font-size: 8pt;
  }

  .col-print-12 {
    width: 100%;
  }

  .col-print-center {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .page-break-inside-avoid {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .chart-print-size {
    width: 50%;
  }

  .col-print-4 {
    width: 33%;
  }

  .linktableheader {
    background-color: transparent;
    color: black;
    border-bottom: 1px solid black;
  }

  .printbutton, .printNav, .dashboard-link, .note-btn, .clc-report-filter-btn {
    display: none;
  }

  .clc-nav {
    position: relative !important;
    width: 100%;
    top: 0;
    margin-left: 0;
    margin-right: 0;
    background-color: $primary;
    -webkit-print-color-adjust: exact;
  }

  .mdp-ref-email {
    overflow-wrap: anywhere;
  }
}

.overflow-hidden {
  .modal-body {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .modal-content {
    height: 126px;
  }
}

.overflow-hidden-error {
  .modal-body {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .modal-content {
    height: 212px;
  }
}

.submitting-pdf {
  margin-top:50px;
  width: 8in;
}

.panel-header {
  border: 1px solid black;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
}

.fit-content {
  width: fit-content;
}

.manage-matching, .survey-modal {
  max-height: unset;
}

.tooltip-inner {
  a {
    color: white;
  }
}

.searchButton {
  margin-top: 31px;
}

/* styles.css */
.circle {
  width: 15px;
  height: 15px;
  margin: 7px 0px 0px 7px;
  border-radius: 50%;
  display: inline-block;
}

.green-circle {
  background-color: green;
}

.yellow-circle {
  background-color: #f6e938;
}

.red-circle {
  background-color: orangered;
}

.new-match {
  font-weight: 900;
}

/* Graph Styles */
.graph-container {
  width: 320px;
  height: 320px;
  display: inline;
}

.report-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.box {
  font-weight: bold;
  border: 2px solid #000; /* Outline */
  margin: 20px 0;
  padding: 20px;
  width: 80%; /* Adjust the width as needed */
  max-width: 600px; /* Optional: set a maximum width */
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
}

.clc-report-filter-btn {
  border-radius: 25px;
  border: none;
}

.clc-position-report-filter-btn {
  border-radius: 25px;
  background-color: #1a438a;
  color: white;
  margin-left: 10px;
}

.clc-report-filter-btn-pressed {
  background-color: #6cc1ed;
  outline-color: none;
  color: $white;
}
.clc-report-filter-btn-pressed:hover {
  background-color: #6cc1ed; /* Same as pressed background color */
}
.clc-report-filter-btn:focus,
.clc-report-filter-btn:active{
  background-color: #6cc1ed;
  color: white; /* Optional: Change text color */
}

.clc-report-filter-btn-inactive:focus,
.clc-report-filter-btn-inactive {
  background-color: #1a438a;
  color: white; /* Optional: Change text color */
}

.clc-report-title {
  text-align: center;
  font-weight: bolder;
}

.positions-table {
  margin-top: 36px;
}

.noFilters::after {
  content: '/';
  display: inline-block;
  margin-left: 5px;
}

.pill-select {
  float: right;
  width: fit-content;
  min-width: 130px;
  max-width: 350px;
  border-radius: 20px;
}

.custom-select {
  background: #1a438a url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px !important;
}

.community-type-checkboxes {
  width: fit-content;
  padding: 0 15px 10px 0px;
}