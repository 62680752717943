.modal {
  z-index: 10000;

  &-body {
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
  }
}

.profilemodalfooter {
  position:absolute;
  bottom:0;
  right: 0;
}

.tooltip {
  z-index: 20000 !important;
}
