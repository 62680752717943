/*
  Elements - HTML Element styling.

  Rules:
    - No class names or IDs. Only standard HTML elements
 */

Form Label {
  font-weight: $fw-bold;
}

.rbt-token-remove-button {
  background-color: transparent;
  border: 0;
}