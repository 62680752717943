.salary-tooltip {
  float: right;
  vertical-align: top;
}

.icon-tooltip {
  display: inline-block;
  padding-left: 6px;
  margin-top: -1px;
  vertical-align: top;
}

.statement-tooltip {
  vertical-align: text-bottom;
  padding-left: 5px;
}

.top-align-tooltip {
  margin-top: -3px;
}
