$footer-height: 55;

.breadcrumb-progress {
  display: flex;
  flex-direction: column;

  &__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0;
  }

  &__component-wrapper {
    padding-bottom: ($footer-height + 20) * 1px;
  }

  &__item {
    position: relative;
    padding: rem(0) rem(20);
    margin: rem(20) 0 rem(5);
    text-align: left;
    border: 0;
    outline: none;
    color: $gray-500;
    font-weight: $fw-thin;
    font-size: rem(14);
    background-color: transparent;

    @include hover-focus-active {
      &:not([disabled]) {
        color: $blue-400;
        cursor: pointer;
      }
    }

    @include media-breakpoint-down(lg) {
      min-width: 150px;
    }

    &--active {
      color: $blue-400;
      text-decoration: underline;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      border-right: 1px solid $gray-500;
      display: block;
      height: rem(7);
      margin-top: -6px;
      transform: rotate(135deg);
      width: 0;
    }

    &::after {
      margin-top: -2px;
      transform: rotate(45deg);
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: rem(8);
    padding-right: rem(8);
    color: $white;
    height: $footer-height * 1px;
    width: 100%;
    background-color: $primary;
    z-index: 1;

    .next-button, .exit-button {
      margin-left: auto;
    }
  }

  &__progression {
    .progress {
      --bs-progress-height: 10px;
      --bs-progress-bg: transparent;
      border: 1px $gray-500 solid;
      width: 100%;
    }
  }

  .scrollbox {
    margin: 0 auto;
    width: 100%;

    overflow-x: scroll;
    background-image:
      linear-gradient(to right, $white, $white),
      linear-gradient(to right, $white, $white),
      linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),
      linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0));

    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
    background-attachment: local, local, scroll, scroll;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      display: none !important;
    }
  }
}
