/*
  Utilities - are for one purpose and one purpose only. Ex: .u-text-center

  Notes:
    - Most utilities should have !important tag.
 */

 .overflow-wrap {
  overflow-wrap: anywhere !important;
 }

 .nowrap {
  white-space: nowrap !important;
 }