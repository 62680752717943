.form-check {
  margin-left: 5px;
  text-align: left;
  padding-top: 1%;

  input[name="pdpcheckbox"],
  input[name="mdpchair"],
  input[name="mdpcom"],
  input[name="mdpclc"],
  input[name="ethnicitycheckbox"] {
    height: 22px;
    width: 22px;
    border: 2px solid $info;
    position: relative;
    left: -5px;
    top: -5px;
  }

  input[name="positionTypeCheck"] {
    position: relative;
    margin-top: 0px;
    margin-right: 10px;
    height: 22px;
    width: 22px;
    border: 2px solid $info;
  }
}

.required:after {
  content: "*";
  color: $red;
  padding-left: 4px;
}

.radiospacer {
  display: inline;

  .form-check-label {
    position: relative;
    top: 0%;
  }

  input[id*="inline"] {
    border-width: 3px;
    border-style: solid;
  }

  input[id*="inline-0"] {
    border-color: $primary;
  }

  input[id*="inline-1"] {
    border-color: $blue-800;
  }

  input[id*="inline-2"] {
    border-color: $blue-600;
  }

  input[id*="inline-3"] {
    border-color: $blue-400;
  }

  input[id*="inline-4"] {
    border-color: $blue-200;
  }

  input[id*="inline-5"] {
    border-color: $secondary;
  }
}

.preferencesradio {
  @include media-breakpoint-up(lg) {
    text-align: left;
  }
}

input[type="radio"] {
  position: relative;
  left: 4px;
  top: 4px;
  height: 22px;
  width: 22px;
  border: 2px solid $info;
  margin-right: 10px;

  @include media-breakpoint-up(lg) {
    top: -2px;
  }
}

.input {
  &--error {
    border-color: $red;

    + .error-text {
      color: $red !important;
    }
  }
}

