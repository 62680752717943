/*
  Objects - are building blocks of a site.

  Rules:
    - They can be used in any number of places across the project—places you might not have even seen.
    - Be careful modifying their styles.
  */

.dashboard-link {
  span {
    margin-left: 10px;
  }
}

.match-characteristics-label {
  font-weight: bold;
}

.desired-score-label {
  border: 2px solid black;
  padding: 3px;
}
