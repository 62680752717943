/*
  settings - colors
 */

// brand colors
$primary: #1a438a;
$secondary: #6cc1ed;
$info: #063966;

// generic
$black: #000000;
$gray-500: #747677;
$gray-100: #cecece;
$white: #ffffff;
$offwhite: #efefef;

// Blues
$blue-800: #1d5885;
$blue-600: #31729e;
$blue-400: #448bb7;
$blue-200: #57a5d1;
