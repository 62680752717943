/*
  Components - are composite pieces of the site (i.e. Navigation or media cards.) 
  AKA Organisms in Atomic Design

  Rules:
    - Mostly contain specific styles to that component set.
  */

@import 'breadcrumbs';
@import 'forms';
@import 'login';
@import 'modals';
@import 'tooltips';

.pill-select {
  float: right;
  width: fit-content;
  min-width: 130px;
  max-width: 350px;
  border-radius: 20px;
}

.custom-select {
  background: #1a438a url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px !important;
}