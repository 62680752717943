@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap");

// Families
$base-font: "Mulish";
$font-family-base: $base-font, 'sans-serif';

// Weights
$fw-thin: 300;
$fw-medium: 400;
$fw-semi-bold: 500;
$fw-bold: 700;

.fw-thin {
  font-weight: $fw-thin;
}

.fw-medium {
  font-weight: $fw-medium;
}

.fw-semi-bold {
  font-weight: $fw-semi-bold;
}

.fw-bold {
  font-weight: $fw-bold;
}
